<i18n>
{
  "de": {
    "addBuildingTitle": "Neue Liegenschaft erfassen",
    "requiredParamsHint": "Hinweis: Für eine realistische Analyse werden die mit einem Stern (*) gekennzeichneten Angaben benötigt. Weitere detaillierte Angaben können in einem zweiten Schritt ergänzt werden.",
    "saveButton": "Liegenschaft hinzufügen",
    "addAddressButton": "Weitere Adresse hinzufügen",
    "identifierTitle": "Liegenschaftsnr.",
    "descriptionTitle": "Bezeichnung",
    "municipalityTitle": "Gemeinde",
    "zipTitle": "Postleitzahl *",
    "buildingYearTitle": "Baujahr Gebäude",
    "buildingYearInfoTitle": "Baujahr Gebäude",
    "renovationYearTitle": "Letzte Sanierung *",
    "renovationYearInfoTitle": "Letzte Sanierung",
    "renovationYearInfoText": "Jahr der letzten umfassenden energetischen Sanierung der Gebäudehülle. Diese Angabe wird für die Einschätzung des Heizwärmebedarfs sowie für die Massnahmenplanung verwendet und ist insbesondere für Gebäude mit Baujahr vor 1990 sehr relevant. Falls genauere Angaben zu Wärmedämmung oder dem Zustand einzelner Bauteile vorhanden sind, können diese nach der erstmaligen Erfassung ergänzt werden.",
    "buildingYearInfoText": "Diese Angabe kann in den meisten Fällen automatisch aus dem Gebäude- und Wohnungsregister ermittelt werden.",
    "heatingTitle": "Wärmeerzeuger *",
    "heatingYearTitle": "Baujahr Heizung",
    "heatingYearInfoTitle": "Baujahr Heizung",
    "heatingYearInfoText": "Das Baujahr der Heizung wird für eine realistische Massnahmenplanung benötigt. Falls unbekannt wird angenommen, dass die Heizung das gleiche Alter wie das Gebäude hat.",
    "heatingInfoTitle": "Heizungstyp",
    "heatingInfoText": "Der Heizungstyp wird für die Modellierung des Energiebedarfs und der Treibhausgasemissionen benötigt. Falls der Heizungstyp nicht bekannt ist, wird von einer Ölheizung ausgegangen. Falls mehrere Wärmeerzeuger vorhandne sind, können diese nach dem erstmaligen Erfassen ergänzt werden.",
    "usageTitle": "Nutzung *",
    "areaTitle": "Fläche *",
    "areaInfoTitle": "Fläche",
    "areaInfoText": "Optimalerweise wird die Energiebezugsfläche (EBF) angegeben. Falls diese nicht bekannt ist, kann sie über die Bruttogeschossfläche (BGF) oder über die Hauptnutzfläche (HNF) hergeleitet werden.",
    "usageInfoTitle": "Nutzung *",
    "usageInfoText": "Die Gebäudenutzung wird für die Abschätzung des Strom-, Warmwasser- und Heizwärmebedarfs benötigt. Falls mehrere Nutzungen vorhanden sind können diese nach dem erstmaligen Erfassen ergänzt werden.",
    "streetTitle": "Strasse/Hausnr. *",
    "pendingMessage": "Die Liegenschaft wird erstellt und GIS-Daten abgefragt. Bitte haben Sie einen Moment Geduld...",
    "areaTypeEbf": "Energiebezugsfläche (EBF)",
    "areaTypeHnf": "Hauptnutzfläche (HNF)",
    "areaTypeBgf": "Bruttogeschossfläche (BGF)"
  }
}
</i18n>

<template>
  <main-layout :portfolio="portfolio" :ready="portfolioReady" class="add-building-page">
    <template #default>
      <h1>{{ $t('addBuildingTitle') }}</h1>
      <p v-if="pending">{{ $t('pendingMessage') }}</p>
      <p v-else-if="error">{{ error }}</p>
      <div class="building-form" v-else>
        <form-row :label="$t('identifierTitle')">
          <text-input v-model="identifier" :edit="true" class="limit-width" />
        </form-row>
        <form-row :label="$t('descriptionTitle')">
          <text-input v-model="description" :edit="true" />
        </form-row>
        <form-row :label="$t('streetTitle')">
          <detail-list class="address-table">
            <li v-for="(a, index) in addresses" :key="index">
              <text-input v-model="a.address" :edit="true" />
              <list-button-wrapper>
                <list-delete-button @click="onDeleteAddress(index)" />
              </list-button-wrapper>
            </li>
          </detail-list>
          <button-wrapper>
            <button class="button" @click.prevent="onAddAddress">{{ $t('addAddressButton') }}</button>
          </button-wrapper>
        </form-row>
        <form-row :label="$t('zipTitle')">
          <text-input v-model="zip_code" :edit="true" class="limit-width" />
        </form-row>
        <form-row :label="$t('municipalityTitle')">
          <text-input v-model="municipality" :edit="true" />
        </form-row>
        <form-row :label="$t('usageTitle')" :info-text="$t('usageInfoText')" :info-title="$t('usageInfoTitle')">
          <zone-usage-picker v-model="usage" />
        </form-row>
        <form-row :label="$t('areaTitle')" :info-text="$t('areaInfoText')" :info-title="$t('areaInfoTitle')">
          <input type="radio" id="area-hnf" value="HNF" v-model="areaType">
          <label for="area-hnf">{{ $t('areaTypeHnf') }}</label>
          <br>
          <input type="radio" id="area-ebf" value="EBF" v-model="areaType" selected>
          <label for="area-ebf">{{ $t('areaTypeEbf') }}</label>
          <br>
          <input type="radio" id="area-bgf" value="BGF" v-model="areaType">
          <label for="area-bgf">{{ $t('areaTypeBgf') }}</label>
          <numeric-input optional v-model="area" :edit="true" :units="'m²'" class="limit-width" />
        </form-row>
        <form-row :label="$t('heatingTitle')" :info-text="$t('heatingInfoText')" :info-title="$t('heatingInfoTitle')">
          <heating-type-picker v-model="heating" :edit="true" />
        </form-row>
        <form-row :label="$t('buildingYearTitle')" :info-text="$t('buildingYearInfoText')" :info-title="$t('buildingYearInfoTitle')">
          <numeric-input optional v-model="buildingYear" :edit="true" :min="0" class="limit-width" :max="new Date().getFullYear()" />
        </form-row>
        <form-row :label="$t('renovationYearTitle')" :info-text="$t('renovationYearInfoText')" :info-title="$t('renovationYearInfoTitle')">
          <numeric-input optional v-model="renovationYear" :edit="true" :min="0" class="limit-width" :max="new Date().getFullYear()" />
        </form-row>
        <form-row :label="$t('heatingYearTitle')" :info-text="$t('heatingYearInfoText')" :info-title="$t('heatingYearInfoTitle')">
          <numeric-input optional v-model="heatingYear" :edit="true" :min="0" class="limit-width" :max="new Date().getFullYear()" />
        </form-row>
        <p class="required-hint">{{ $t('requiredParamsHint') }}</p>
        <button-wrapper>
          <button class="button" @click.prevent="onSave">{{ $t('saveButton') }}</button>
        </button-wrapper>
      </div>
    </template>
  </main-layout>
</template>

<script>
import MainLayout from '@/pages/layouts/MainLayout'
import AllPortfoliosMixin from '@/pages/vuex-mixins/AllPortfoliosMixin'
import PortfolioMixin from '@/pages/vuex-mixins/PortfolioMixin'

import ListButtonWrapper from '@/components/shared/lists/ListButtonWrapper'
import ListDeleteButton from '@/components/shared/lists/ListDeleteButton'
import compassApi from '@/services/compass-api'
import ButtonWrapper from '@/components/shared/ButtonWrapper'
import TextInput from '@/components/shared/forms/TextInput'
import NumericInput from '@/components/shared/forms/NumericInput'
import DetailList from '@/components/shared/lists/DetailList'
import ZoneUsagePicker from '@/components/building/edit/ZoneUsagePicker'
import HeatingTypePicker from '@/components/building/edit/HeatingTypePicker'
import FormRow from '@/components/shared/forms/FormRow'

export default {
  mixins: [
    AllPortfoliosMixin, // Provides: allPortfolios, allPortfoliosReady
    PortfolioMixin // Provides: portfolio, portfolioLoading, portfolioReady
  ],
  data () {
    return {
      identifier: '',
      description: '',
      municipality: '',
      zip_code: '',
      area: null,
      areaType: 'HNF',
      buildingYear: null,
      renovationYear: null,
      heating: 'OIL',
      heatingYear: null,
      usage: 'RESIDENTIAL',
      addresses: [
        {
          address: ''
        }
      ],
      pending: false,
      error: null
    }
  },
  methods: {
    onAddAddress () {
      this.addresses.push({
        'address': ''
      })
    },
    onDeleteAddress (index) {
      this.addresses.splice(index, 1)
    },
    async onSave () {
      this.pending = true
      try {
        const building = {
          identifier: this.identifier,
          description: this.description,
          municipality: this.municipality,
          zip_code: this.zip_code,
          addresses: this.addresses.map(a => a.address).join(';'),
          initial_state: {
            envelope: {
              building_year: this.buildingYear ? this.buildingYear : null,
              basement: {
                year: this.renovationYear ? this.renovationYear : null
              },
              walls: {
                year: this.renovationYear ? this.renovationYear : null
              },
              windows: {
                year: this.renovationYear ? this.renovationYear : null
              },
              roof: {
                year: this.renovationYear ? this.renovationYear : null
              }
            },
            heating: {
              year: this.heatingYear ? this.heatingYear : null,
              heaters: [
                {
                  type: this.heating
                }
              ]
            },
            zones: {
              zones: [{
                usage: this.usage,
                energy_area: this.areaType === 'EBF' ? this.area : null,
                floor_area: this.areaType === 'BGF' ? this.area : null,
                rental_area: this.areaType === 'HNF' ? this.area : null
              }]
            }
          }
        }
        const buildingResponse = await compassApi.call(`/portfolios/${this.portfolio.id}/buildings`, 'POST', building)
        this.$router.push({ name: 'building', params: { portfolio_id: this.portfolio.id, building_id: buildingResponse.id } })
      } catch (error) {
        this.error = error
      }
      this.pending = false
    }
  },
  components: {
    ListDeleteButton,
    ListButtonWrapper,
    ButtonWrapper,
    MainLayout,
    TextInput,
    NumericInput,
    DetailList,
    ZoneUsagePicker,
    HeatingTypePicker,
    FormRow
  }
}
</script>

<style>
.add-building-page .building-form {
  max-width:650px;
}
.add-building-page .detail-list.address-table > ul > li {
  grid-template-columns: 1fr 50px
}
.add-building-page .limit-width {
  max-width: 100px;
}
.add-building-page .required-hint {
  margin-bottom: var(--spacing-m);
}
</style>
